import React from 'react'
import clipboardCopy from 'clipboard-copy';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';

import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useGetTransactionStatusMutation, useInitiatePNYXMutation } from '../app/API'

import { setStatus, setTDS, setRedirect, setApproved, resetTransaction } from '../slices/TransactionSlice'
import { resetData } from '../slices/DataSlice'
import { setPage } from '../slices/AppSlice'

import { ContentCopy, TimerOutlined } from '@mui/icons-material';
import { Typography, Alert, Box, Button, IconButton, Snackbar, TextField, Card, Stack, ButtonBase } from '@mui/material'
import { Timer } from '../components/Timer'

export default function TransactionForm() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [snackbarOpen, setSnackbarOpen] = React.useState(false)

  const [cardNumber, setCardNumber] = React.useState('');
  const [cardName, setCardName] = React.useState('');
  const [expirationDate, setExpirationDate] = React.useState('');
  const [cvc, setCVC] = React.useState('');
  const [focused, setFocused] = React.useState('');
  const [isPaymentButtonDisabled, setPaymentButtonDisabled] = React.useState(true);

  const [initiatePNYX, { isLoading: isInitiatingPNYX }] = useInitiatePNYXMutation();

  const {
    referer, price, currency, id, data, qrcode, method, type, name, recipient, owner, date, duplicate, status, tds, redirect, approved, sendbutton
  } = useSelector(state => ({
    referer: state.root.data.referer,
    price: state.root.data.price,
    currency: state.root.data.currency,
    id: state.root.transaction.id,
    data: state.root.transaction.data,
    qrcode: state.root.transaction.qrcode,
    method: state.root.transaction.method,
    type: state.root.transaction.type,
    owner: state.root.transaction.owner,
    name: state.root.transaction.name,
    recipient: state.root.transaction.recipient,
    date: state.root.transaction.date,
    status: state.root.transaction.status,
    duplicate: state.root.transaction.duplicate,
    tds: state.root.transaction.tds,
    redirect: state.root.transaction.redirect,
    approved: state.root.transaction.approved,
    sendbutton: state.root.auth.sendbutton
  }));

  const [getTransactionStatus] = useGetTransactionStatusMutation()

  const handleOpenQrCode = () => {
    window.open(qrcode, '_blank');
  };

  const handleCopyData = async () => {
    try {
      await clipboardCopy(data);
      setSnackbarOpen(true);
    } catch (e) {
      console.log(e)
    }
  };

  const handleCopyPrice = async () => {
    try {
      await clipboardCopy(price);
      setSnackbarOpen(true);
    } catch (e) {
      console.log(e)
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const reject = async () => {
    if (window.confirm(t('transaction_reject_user_message'))) {
      dispatch(setStatus('Rejected'))
    }
  }

  const handlePNYX = async () => {
    if (!cardNumber || !cardName || !expirationDate || !cvc) {
      alert(t('ecom_card_alert'));
      return;
    }

    try {
      setPaymentButtonDisabled(true);
      const response = await initiatePNYX({ card: cardNumber, owner: cardName, expiration: expirationDate, cvc, price, currency, id }).unwrap();

      if (response.tds) {
        dispatch(setTDS(true));
        window.location.href = response.tds;
      } else {
        if (response.status) {
          dispatch(setStatus('Complete'))
        } else {
          dispatch(setStatus('Rejected'))
        }
      }
    } catch (e) {
      alert(t('ecom_card_alert'));
      console.log(e.data.error);
    } finally {
      setPaymentButtonDisabled(false);
    }
  };

  const restart = async () => {
    dispatch(resetTransaction())
    dispatch(resetData())
    dispatch(setPage(0))
  }

  const logout = async () => {
    window.location.href = referer
  };

  function clearNumber(value = '') {
    return value.replace(/\D+/g, '');
  }

  function formatCardNumber(value) {
    if (!value) {
      return value;
    }

    const clearValue = clearNumber(value);
    return `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`.trim();
  }

  function formatCVC(value) {
    const clearValue = clearNumber(value);
    return clearValue.slice(0, 4);
  }

  function formatExpirationDate(value) {
    const clearValue = clearNumber(value);

    if (clearValue.length >= 3) {
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }

    return clearValue;
  }

  React.useEffect(() => {
    if (status !== 'Complete' && status !== 'Rejected' && id) {
      const i = setInterval(async () => {
        const response = await getTransactionStatus({ id })
        if (response.data.status !== status) {
          dispatch(setStatus(response.data.status))
        }
      }, 4000)

      return () => clearInterval(i)
    }
  }, [status, id, getTransactionStatus, dispatch])

  React.useEffect(() => {
    const isCardNumberValid = /^\d{16,}$/.test(cardNumber.replace(/\s+/g, ''));
    const isCardNameValid = cardName.trim().length > 0;
    const isExpirationDateValid = /^(0[1-9]|1[0-2])\/[0-9]{2}$/.test(expirationDate);
    const isCvcValid = /^[0-9]{3,4}$/.test(cvc);

    const isFormValid = isCardNumberValid && isCardNameValid && isExpirationDateValid && isCvcValid;

    setPaymentButtonDisabled(!isFormValid);
  }, [cardNumber, cardName, expirationDate, cvc]);

  React.useEffect(() => {
    if (data && method === 'ECOM' && type === 'SBBC' && status === 'Pending' && redirect === false) {
      dispatch(setRedirect(true));
      window.location.href = data;
    }
  }, [data, method, type, status, redirect, dispatch]);

  return (
    <React.Fragment>
      {status === 'Pending' && tds !== true && <Alert variant="outlined" severity="info" sx={{ mb: 4 }}>
        {t('pending_payment')}<br />
        {t('id')}: {id}
      </Alert>}

      {status === 'Pending' && tds === true && <Alert variant="outlined" severity="info" sx={{ mb: 4 }}>
        {t('tds_payment')}<br />
        {t('id')}: {id}
      </Alert>}

      {status === 'Rejected' && <Box>
        <Alert severity="warning" sx={{ mb: 4 }}>
          {duplicate ? (
            <b>{t('payment_error_1')} {t('payment_error_2')} {t('payment_error_3')} <Typography display="inline" sx={{ textDecoration: "underline", color: '#f50000' }}>{t('payment_error_4')}</Typography>.</b>
          ) : (
            <>
              {t('payment_rejection')}
              <br />
              {t('id')}: {id}
            </>
          )}
        </Alert>
      </Box>}

      {status === 'Complete' && <Box>
        <Alert severity="success" sx={{ mb: 4 }}>
          {t('successful_payment')}<br />
          {t('id')} {id}
        </Alert>
      </Box>}

      {status === 'Pending' && <Box>
        {tds !== true && <Box>
          {method == 'P2P' && <Card variant="outlined" sx={{ display: 'flex', justifyContent: 'space-between', border: "none", flexDirection: 'column', backgroundImage: "linear-gradient(136deg, rgb(35 34 33) 0%, rgb(82 81 81) 50%, rgb(38 38 38) 100%)", color: 'white', borderRadius: 4, padding: 1, marginBottom: 4, }}>
            <ButtonBase sx={{ display: 'block', textAlign: 'initial', width: '100%', padding: '8px 16px', borderRadius: '12px' }} onClick={handleCopyPrice} >
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Typography variant="subtitle2">
                    {t('payment_pay')}:
                  </Typography>
                  <Typography variant="h5">
                    {price} {currency}
                  </Typography>
                </Box>
                <IconButton color="inherit" sx={{ alignSelf: 'center' }}>
                  <ContentCopy />
                </IconButton>
              </Stack>
            </ButtonBase>

            <ButtonBase sx={{ display: 'block', textAlign: 'initial', width: '100%', padding: '8px 16px', borderRadius: '12px' }} onClick={handleCopyData} >
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Typography variant="subtitle2">
                    {t('payment_here')}:
                  </Typography>
                  <Typography variant="h5">
                    {data}
                  </Typography>
                </Box>
                <IconButton color="inherit" sx={{ alignSelf: 'center' }}>
                  <ContentCopy />
                </IconButton>
              </Stack>
            </ButtonBase>

            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ padding: '8px 16px', }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography variant="subtitle2">
                  {owner}
                </Typography>
                <Typography variant="subtitle2">
                  {recipient || name}
                </Typography>
              </Box>
              <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                <TimerOutlined /> <Timer datetime={date} />
              </Typography>
            </Stack>
          </Card>}

          {method == 'ECOM' && type == 'PNYX' && (
            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: 3 }}>
              <Box sx={{ flex: '1 1 auto' }}>
                <Cards
                  number={cardNumber}
                  name={cardName}
                  expiry={expirationDate}
                  cvc={cvc}
                  focused={focused}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, paddingTop: 2, marginLeft: '5px', marginRight: '5px', }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                  <TextField
                    name="cc-number"
                    inputProps={{ autoComplete: "cc-number" }}
                    sx={{ flex: 3 }}
                    label={t('card_number')}
                    variant="standard"
                    value={cardNumber}
                    onChange={e => setCardNumber(formatCardNumber(e.target.value))}
                    onFocus={() => setFocused('number')}
                  />
                  <TextField
                    name="cc-exp"
                    inputProps={{ autoComplete: "cc-exp" }}
                    sx={{ flex: 1 }}
                    label="MM/YY"
                    variant="standard"
                    value={expirationDate}
                    onChange={e => setExpirationDate(formatExpirationDate(e.target.value))}
                    onFocus={() => setFocused('expiry')}
                  />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                  <TextField
                    name="cc-name"
                    inputProps={{ autoComplete: "cc-name" }}
                    sx={{ flex: 3 }}
                    label={t('cardholder_name')}
                    variant="standard"
                    value={cardName}
                    onChange={e => setCardName(e.target.value)}
                    onFocus={() => setFocused('name')}
                  />
                  <TextField
                    name="cc-csc"
                    inputProps={{ autoComplete: "cc-csc" }}
                    sx={{ flex: 1 }}
                    label="CVC"
                    variant="standard"
                    value={cvc}
                    onChange={e => setCVC(formatCVC(e.target.value))}
                    onFocus={() => setFocused('cvc')}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>}
      </Box>}

      {status === 'Pending' && method == 'P2P' && approved && <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 'auto' }}>
        <Alert severity="warning" sx={{ minWidth: '100%', }}>
          {method == 'P2P' && <>
            {t('payment_ok_text')}
          </>}
        </Alert>
      </Box>}

      {status === 'Pending' && method == 'P2P' && !approved && <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 'auto' }}>
        <Alert severity="error" sx={{ minWidth: '100%', }}>
          {method == 'P2P' && <>
            {t('payment_waiting_text_1')} {price} {currency} {t('payment_waiting_text_2')}
          </>}
        </Alert>
      </Box>}

      {status === 'Pending' && method == 'ECOM' && type == 'PNYX' && tds !== true && <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="outlined" color="primary" onClick={handlePNYX} fullWidth sx={{ mt: 2, }} disabled={isPaymentButtonDisabled || isInitiatingPNYX}>
          {t('pay')}
        </Button>
      </Box>}

      {status === 'Pending' && tds !== true && sendbutton && !approved && <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 'auto' }}>
        <Button variant="contained" color="success" onClick={() => dispatch(setApproved(true))} fullWidth sx={{ mt: 2, }}>
          {t('approve_transaction')}
        </Button>
      </Box>}

      {status === 'Pending' && tds !== true && !approved && <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 'auto' }}>
        <Button variant="outlined" color="error" onClick={reject} fullWidth sx={{ mt: sendbutton ? 1 : 2, }}>
          {t('cancel_transaction')}
        </Button>
      </Box>}

      {!referer && status !== 'Pending' && <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 'auto' }}>
        <Button variant="outlined" color="inherit" onClick={restart} fullWidth sx={{ mt: 2, }}>
          {t('place_a_new_order')}
        </Button>
      </Box>
      }
      {referer && ((status !== 'Pending') || approved) && <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 'auto' }}>
        <Button variant="outlined" color="inherit" onClick={logout} fullWidth sx={{ mt: 2, }}>
          {t('back_to_store')}
        </Button>
      </Box>}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        message={t('copied')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </React.Fragment >
  )
}
